.attribute-name {
    display: inline-block;
    min-width: 190px;
}

.attribute-control {
    display: inline-block;
}

.record-form {
    margin: 10px 0;
}

.ant-form-item {
    margin-bottom: 10px;
}