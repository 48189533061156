label {
    font-size: 1.2em;
    font-weight: 400;
}

.content {
    text-align: center;
    margin: 50px 0;
}

.attributes {
    text-align: left;
    margin-top: 100px;
}

.attribute {
    margin: 20px 0;
}

.total {
    margin: 10px 0;
    font-size: 1.3em;
}