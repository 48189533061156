.with-alert {
    font-weight: bold;
}

.vehicle-activity-active {
    color: #3F3D56;
}
.vehicle-activity-blocked {
    color: #D35858;
}

.div-color-TO {
    background: rgba(88, 211, 165, 0.115);
    border-color: rgba(88, 211, 165, 0.999);
    margin: 10px;
    padding: 15px;
}

.div-color-OSAGO {
    background: rgba(211, 88, 88, 0.115);
    border-color: rgba(211, 88, 88, 0.999);
    margin: 10px;
    padding: 15px;
}

.div-color-DK {
    background: rgba(250, 204, 43, 0.115);
    border-color: rgba(250, 204, 43, 0.999);
    margin: 10px;
    padding: 15px;
}