.driver-details-header {
    margin: 5px 0;
    padding: 0 10px;
    border-radius: 5px;
    width: 100%;
    vertical-align: top;
}
.driver-balance {
    text-align: center;
}

.avatar-uploader > .ant-upload {
    width: 72px;
    height: 72px;
}