.user-form {
  max-width: 840px;
  margin: 50px auto;
}

.user-form h1 {
  font-size: 2.5em;

}

.subtitle {
  text-align: center;
  max-width: 460px;
  margin: 20px auto;
}

.form-fields {
  max-width: 600px;
  margin: 0 auto;
}

.form-button {

}