.bordered-row td,
.bordered-row:last-child td {
  border: 1px solid #cfcecf;
  border-right: 0;
}
.bordered-row td:first-child {
  border: 0;
}
.bordered-row td:last-child {
  border: 0;
  border-left: 1px solid #cfcecf;
}