.list-item {
    padding: 10px 30px;
    border-bottom: 1px solid #EEE;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.list-item .label {
    display: inline-block;
}