.check-failed {
    color: darkred;
    font-weight: bold;
}

.check-success {

}

.inspection-details-modal-style {
    min-width: 90%;
}