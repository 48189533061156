.driver-activity-active {
    color: #3F3D56;
}
.driver-activity-blocked {
    color: #D35858;
}
.driver-status-offline {
    opacity: 0.6;
}
.driver-workcondition-alert {
    font-weight: bold;
}
tr.row-odd {
    background-color: #F7F7F7;
}
td.ant-table-column-sort {
    background-color: unset;
}
.badge {
    display:inline-block;
    min-width:10px;
    padding:6px 10px;
    font-size:12px;
    font-weight:700;
    line-height:1;
    vertical-align:middle;
    white-space:nowrap;
    text-align:center;
    background-color:#777;
    border-radius:10px
}
.badge,
.badge:hover {
    color:#fff
}
.badge--success {
    background:#4caf50
}
.badge--warning {
    background:#f44336
}
.badge--saved {
    background:#2196f3
}
.badge--processed {
    background:#ff9800
}
.badge--completed {
    background:#4caf50
}
.badge--pointer {
    cursor:pointer
}
.badge--oval {
    width:27px;
    height:12px
}
.badge--cancelled,
.badge--failed {
    background:#f44336
}
.badge--driving,
.badge--transporting {
    background:#2196f3
}
.badge--complete {
    background:#4caf50
}
.badge--free {
    background:#58D3A5
}
.badge--in_order,
.badge--in_order_busy,
.badge--in_order_free {
    background:#FACC2B
}
.badge--busy {
    background:#D35858
}
.badge--offline {
    background:#DBDBDB
}
.badge--new {
    background:#2196f3
}
.badge--accepted {
    background:#4caf50
}
.badge--ended {
    background:#c03df7
}
.badge--rejected {
    background:#ff9800
}
.badge--terminated {
    background:#ff5767
}
.driver-comment .ant-comment-inner {
    padding: 4px 0;
}

.driver-comment .ant-comment-actions {
    margin-top: 0;
}

.driver-candidates-modal-style {
    min-width: 55%;
}

.drivers-page-modal-style {
    min-width: 30%;
}

.transactions-modal-style {
    min-width: 55%;
}

.select-text {
    white-space: nowrap;
}

.select-text-for-wrap {
    white-space: pre-wrap;
}

.list-scroll {
    height: 300px;
    overflow-y: scroll;
    border-radius: 10px;
}

.list-style {
    /* height: 30px; */
    margin-bottom: 0;
}

.ant-tooltip-inner {
    color: black;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 15px 2px rgb(0 0 0 / 0.2);
}

.ant-tooltip-arrow-content{
    background-color: #fafafa !important;
    border-radius: 2px;
}

.tooltip-text-style {
    color: #fafafa;
    text-decoration: underline #fafafa dashed;
    margin-bottom: 0px;
}

