@import '~antd/dist/antd.less';

.ant-input-affix-wrapper > input.ant-input {
    padding-left: 6px;
}

.ant-picker-input> input {
    padding-left: 6px;
}

/* мигание */
.blink {
    animation: blinker 0.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
    from { opacity: 1; }
    to { opacity: 0; }
}

.input-validation-fail input {
    border-color: darkred;
    border-width: 1px;
}
@btn-danger-bg: #D35858;@btn-border-radius-base: 30px;@btn-height-lg: 50px;@select-item-active-bg: rgba(88, 211, 165, 0.16);@menu-dark-item-active-bg: rgba(88, 211, 165, 0.55);@menu-dark-bg: #3F3D56;@menu-dark-inline-submenu-bg: #3F3D56;@menu-dark-color: #FFFFFF;@layout-sider-background: #3F3D56;@layout-sider-background-light: #3F3D56;@layout-header-background: #FAFAFC;@input-bg: rgba(255, 255, 255, 0.01);@border-radius-base: 20px;@text-color: #3F3D56;@primary-color: #58D3A5;@body-background: #FAFAFC;@checkbox-check-bg: rgba(255, 255, 255, 0.01);@checkbox-border-width: 2px;@table-bg: #F4F4F9;@table-header-color: #FFFFFF;@table-header-bg: #3F3D56;@table-header-sort-bg: #3F3D56;@table-border-radius-base: 20px;@table-header-sort-active-bg: #514F69;@table-fixed-header-sort-active-bg: #514F69;