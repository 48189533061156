.ant-layout-header {
    height: 64px;
    padding: 0 10px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 64px;
}

.logo {
    height: 32px;
    width: 160px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
    float: left;
}

.site-layout {
    height: 100%;
    width: 100%;
    background: #FAFAFC;
}

.header-background {
    background: #FAFAFC;
    /* border: 50px; */
}

.menu-background:hover {
    background: linear-gradient(270deg, rgba(88, 211, 165, 0.315) 1.6%, rgba(88, 211, 165, 0) 100%);
}

.summary-item {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #3F3D56;
}

.summary-item:last-child {
    border-right: 0px
}

.flexbox-container {
    display: flex;
    flex-direction: row;
}