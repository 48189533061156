h1 {
  font-size: 2.5em;
}

.user-form {
  max-width: 840px;
  margin: 10px auto;
}

.user-form h1 {
  font-size: 2.5em;
}

.user-form .ant-form-item-label > label {
  font-size: 14pt;
}

.user-form .ant-input {
  padding: 10px 11px;
  font-size: 16px;
}

.subtitle {
  text-align: center;
  max-width: 460px;
  margin: 20px auto;
}

.form-fields {
  max-width: 600px;
  margin: 0 auto;
}

.form-button {

}