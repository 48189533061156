.disabledContent {
    pointer-events: none;
    opacity: 0.4;
}

.fine-details-modal-style {
    min-width: 90%;
}

.export-confirm-modal-style {
    min-width: 70%;
}

