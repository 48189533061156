.photo-new {

}

.photo-requested {
  opacity: 0.5;
}

.photo-updated {
  border: 5px solid darkgreen;
}

.control-details-modal-style {
  min-width: 90%;
}