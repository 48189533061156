.drag-item {
  padding: 10px;
  border-radius: 6px;
  border-top: 4px solid #1b3fc2;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: white;
  margin: 0 0 8px 0;
  display: grid;
  grid-gap: 20px;
  flex-direction: column;
}

.list-grid {
  padding: 10px;
  border-radius: 6px;
  display: flex;
  grid-gap: 20px;
  flex-direction: row;
  min-height: 1000px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.droppable-style {
  padding: 10px;
  border-radius: 6px;
  background-color: #F4F4F9;
  min-width: 370px;
  height: 95%;
  overflow-y: scroll;
  padding: 5px;
}

.droppable-style::-webkit-scrollbar {
  width: 7px;
  background-color: #f9f9fd;
}

.droppable-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: grey;
}

.droppable-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
  border-radius: 10px;
  background-color: #f9f9fd;
}

.sticky {
  position: sticky;
  top: 0;
  min-height: 2em;
}